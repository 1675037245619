import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import remotorizacion from "assets/imgPropias/services/motor7.jpg";
import aire from "assets/imgPropias/services/aire.jpg";
import aceite from "assets/imgPropias/services/aceite.jpg";
import mantenimiento from "assets/imgPropias/services/mantenimiento.jpg";
import peritaje from "assets/imgPropias/services/peritaje.jpg";
import generador from "assets/imgPropias/services/generador.jpg";
import corrosion from "assets/imgPropias/services/helice3.jpg";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Link from '@material-ui/core/Link';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";


const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    services: "Nuestros Servicios",
    servicesDesc: "Servicio certificado y acreditado con 15 años de experiencia",
    motor: "Remotorización",
    motorDesc:
    "Un nuevo motor proporciona más fiabilidad, mejor consumo e incremento del valor de la embarcación",
    maintenance: "Mantenimiento y Puesta a punto",
    maintenanceDesc:
    "Un buen mantenimiento y una buena puesta a punto es la parte más importante para disfrutar sin problemas mecánicos de su embarcación",
    accessory: "Accesorio náutico",
    accessoryDesc:
    "(Aire Acondicionado, Desalinizadora, Potabilizadora, ...)",
    state: "Informes de estado",
    stateDesc:
    "informes de estado de embarcaciones y Peritajes",
    electro: "Electrólisis, Corrosión y Galvánica",
    electroDesc:
    "Las pruebas de corrosión y electrólisis nos proporcionan información del estado de cualquier herraje",
    oil: "Análisis de aceite",
    oildesc:
    "El análisis de aceite Volvo Penta nos proporciona la información necesaria para conocer el estado real del motor",
    generators: "Generadores",
    generatorsDesc:
    " ",
  },
  {
    id: "ca",
    services: "Els nostres Serveis",
    servicesDesc: "Servei certificat i acreditat amb 15 anys d'experiència",
    motor: "Remotorització",
    motorDesc:
    "Un nou motor proporciona més fiabilitat, millor consum i increment de la valor de l'embarcació",
    maintenance: "Manteniment i Posada a punt",
    maintenanceDesc:
    "Un bon manteniment i una bona posada a punt és la part més important per gaudir sense problemes mecànics de la seva embarcació",
    accessory: "Accessori nàutic",
    accessoryDesc:
    "(Aire Acondicionat, Dessalinitzadora, Potabilitzadora, ...)",
    state: "Informes d'estat",
    stateDesc:
    "Informes d'estat d'embarcacions i Peritatges",
    electro: "Electròlisi, Corrosió i Galvànica",
    electroDesc:
    "Les proves de corrosió i electròlisi ens proporcionen informació de l'estat de qualsevol ferramenta",
    oil: "Anàlisi d'oli",
    oildesc:
    "L'anàlisi d'oli Volvo Penta ens proporciona la informació necessària per conèixer l'estat real de l'motor",
    generators: "Generadors",
    generatorsDesc:
    "",
  },
  {
    id: "en",
    services: "Our Services",
    servicesDesc: "Certified and accredited service with 15 years of experience",
    motor: "Remotorisation",
    motorDesc:
    "A new engine provides more reliability, better fuel consumption and increased value of the boat",
    maintenance: "Maintenance and overhaul",
    maintenanceDesc:
    "Good maintenance and tuning is the most important part of enjoying your boat without mechanical problems", 
    accessory: "Nautical accessory",
    accessoryDesc:
    "(Air Conditioning, Desalination, Watermaker, ...)",
    state: "Status reports",
    stateDesc:
    "vessel status reports and surveys",
    electro: "Electrolysis, Corrosion and Galvanic",
    electroDesc:
    "Corrosion and electrolysis tests provide us with information on the condition of any hardware", 
    oil: "Oil analysis",
    oildesc:
    "Volvo Penta oil analysis provides us with the information we need to know the real condition of the engine", 
    generators: "Generators",
    generatorsDesc:
    " ",
  },
];

export default function ProductSection(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const idioma=langData.find(p => p.id === props.lang);
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{idioma.services}</h2>
          <h5 className={classes.description}>
            {idioma.servicesDesc}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
      <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <a href="#Remotorizacion"><img src={remotorizacion} alt="..." className={imageClasses} /></a>
              </GridItem>
              <h4 className={classes.cardTitle}>
              <Link href="#Remotorizacion" color="inherit" >{idioma.motor} </Link>
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {idioma.motorDesc}
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={generador} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.generators} 
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                {idioma.generatorsDesc} 
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={mantenimiento} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.maintenance} 
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {idioma.maintenanceDesc} 
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={aceite} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.oil} 
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                {idioma.oildesc} 
                </p>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={corrosion} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.electro} 
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {idioma.electroDesc} 
                </p>
              </CardBody>
            </Card>
          </GridItem>
         
          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={peritaje} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.state} 
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {idioma.stateDesc} 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          

          <GridItem xs={12} sm={6} md={4}>
            <Card plain>
              <GridItem xs={8} sm={8} md={8} className={classes.itemGrid}>
                <img src={aire} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                {idioma.accessory}
                <br />
                 
              </h4>
              <CardBody>
                <p className={classes.description}>
                  {idioma.accessoryDesc}
                </p>
              </CardBody>
            </Card>
          </GridItem>
        
        </GridContainer>
      </div>
    </div>
  );
}
