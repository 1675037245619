/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, Build, Mail, Phone, Store, ShoppingBasket,GroupWork } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import flagEs from "assets/img/flags/es.png";
import flagEn from "assets/img/flags/en.png";
import flagCa from "assets/img/flags/ca.png";

const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    name: "Castellano",
    service: "Servicios",
    shop: "Tienda Volvo-Penta",
    contact: "Contacto",
    twitter: "Síguenos en Twitter",
    facebook: "Síguenos en Facebook",
    instagram: "Síguenos en Instagram",
    motor: "Remotorización",
    maintenance: "Mantenimiento y Puesta a punto",
    accessory: "Accesorio náutico",
    state: "Informes de estado",
    electro: "Electrólisis, Corrosión y Galvánica",
    oil: "Análisis de aceite",
    generators: "Generadores",
    partners: "Asociados",
  },
  {
    id: "ca",
    name: "Català",
    service: "Serveis",
    shop: "Tenda Volvo-Penta",
    contact: "Contacte",
    twitter: "Síguenos en Twitter",
    facebook: "Síguenos en Facebook",
    instagram: "Síguenos en Instagram",
    motor: "Remotorització",
    maintenance: "Manteniment i Posada a punt",
    accessory: "Accessori nàutic",
    state: "Informes d'estat",
    electro: "Electròlisi, Corrosió i Galvànica",
    oil: "Anàlisi d'oli",
    generators: "Generadors",
    partners: "Associats",
  },
  {
    id: "en",
    name: "English",
    service: "Services",
    shop: "Volvo-Penta Shop",
    contact: "Contact",
    twitter: "Síguenos en Twitter",
    facebook: "Síguenos en Facebook",
    instagram: "Síguenos en Instagram",
    motor: "Remotorisation",
    maintenance: "Maintenance and overhaul",
    accessory: "Nautical accessory",
    state: "Status reports",
    electro: "Electrolysis, Corrosion and Galvanic",
    oil: "Oil analysis",
    generators: "Generators",
    partners: "Partners",
  },
];




export default function HeaderLinks(props) {
  const classes = useStyles();
  const idioma=langData.find(p => p.id === props.lang);

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="https://www.volvopentashop.com/es-ES/SelectVpc/ChooseServiceDealer?serviceDealerId={280b4f4a-aa9d-4384-b2af-fd2999822b8d}&serviceDealerType=0"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Store className={classes.icons} /> {idioma.shop}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="#contacto"
          color="transparent"
          target=""
          className={classes.navLink}
        >
          <Phone className={classes.icons} /> {idioma.contact}
        </Button>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Síguenos en facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/volvopenta"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Síguenos en instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/sugamarnautica/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/es"
          color="transparent"
          target=""
          className={classes.navLink}
        >
          <img src={flagEs} height="24" />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/ca"
          color="transparent"
          target=""
          className={classes.navLink}
        >
          <img src={flagCa} height="24" />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/en"
          color="transparent"
          target=""
          className={classes.navLink}
        >
          <img src={flagEn} height="24" />
        </Button>
      </ListItem>
    </List>
  );
}
