import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import unknown from "assets/img/faces/unknown.jpg";
import { Phone } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    name: "Castellano",
    team: "",
  },
  {
    id: "ca",
    name: "Català",
    team: "",
  },
  {
    id: "en",
    name: "English",
    team: "",
  },
];

export default function PartnerSection(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const idioma=langData.find(p => p.id === props.lang);  
  return (
    <div className={classes.section} id="partner">
      <h2 className={classes.title}>{idioma.team}</h2>
      <div>

      </div>
    </div>
  );
}
