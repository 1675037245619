import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import IndexPage from "views/IndexPage/IndexPage.js"

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/"><IndexPage lang="es"/></Route> 
      <Route path="/es"><IndexPage lang="es"/></Route>
      <Route path="/ca"><IndexPage lang="ca"/></Route>
      <Route path="/en"><IndexPage lang="en"/></Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);
