import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Pie.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import ContactSection from "./Sections/ContactSection.js";
import PartnerSection from "./Sections/PartnerSection.js";
import RemotorSection from "./Sections/Remotorizacion.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    name: "Castellano",
    title:
      "Mantenimiento de embarcaciones. Servicio Oficial Volvo Penta Port Ginesta",
  },
  {
    id: "ca",
    name: "Català",
    title:
      "Manteniment de vaixells. Servei Oficial Volvo Penta Port Ginesta",
  },
  {
    id: "en",
    name: "English",
    title:
      "Boat maintenance. Volvo Penta Service Port Ginesta",
  },
];




export default function IndexPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const idioma=langData.find(p => p.id === props.lang);
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={"Sugamar Náutica SL"}
        rightLinks={<HeaderLinks lang={props.lang} />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/imgPropias/portada/p1.jpg")}> 
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}></h1>
              <h2>{idioma.title}</h2>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection lang={props.lang} />
          <ContactSection lang={props.lang} />
          <RemotorSection lang={props.lang} />
          <TeamSection lang={props.lang} />
          <PartnerSection lang={props.lang} />
          <PartnerSection lang={props.lang} />

        </div>
      </div>
      <Footer />
    </div>
  );
}
