import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Carousel from "react-slick";


import image1 from "assets/imgPropias/motor1.jpg";
import image2 from "assets/imgPropias/motor2.jpg";
import image3 from "assets/imgPropias/motor3.jpg";
import image5 from "assets/imgPropias/motor5.jpg";
import image6 from "assets/imgPropias/motor6.jpg";
import image8 from "assets/imgPropias/motor8.jpg";
import image9 from "assets/imgPropias/motor9.jpg";
import image10 from "assets/imgPropias/motor10.jpg";
import image11 from "assets/imgPropias/motor11.jpg";
import image12 from "assets/imgPropias/motor12.jpg";
import image13 from "assets/imgPropias/motor13.jpg";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";


const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    name: "Castellano",
    motor: "Remotorización",
    motorDesc:
    "Un nuevo motor proporciona más fiabilidad, mejor consumo e incremento del valor de la embarcación",
  },
  {
    id: "ca",
    name: "Català",
    motor: "Remotorització",
    motorDesc:
    "Un nou motor proporciona més fiabilitat, millor consum i increment de la valor de l'embarcació",
  },
  {
    id: "en",
    name: "English",
    motor: "Remotorisation",
    motorDesc:
    "A new engine provides more reliability, better fuel consumption and increased value of the boat",
  },
];
export default function Remotorizacion(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  const idioma=langData.find(p => p.id === props.lang);
  return (
    <div className={classes.section} id="Remotorizacion">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{idioma.motor}</h2>
          <h5 className={classes.description}>
            {idioma.motorDesc}
          </h5>
        </GridItem>
      </GridContainer>
      <div>
      <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      {idioma.motor}
                    </h4>
                  </div>
                </div>
                
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image10} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image11} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image12} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image13} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                    {idioma.motor}
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>

            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <p>
                    {idioma.motor} footer
                  </p>
            </GridItem>
          </GridContainer>
      </div>
    </div>
  );
}
